#indicator {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin: 5px;
    padding: 5px;
    border: 1px solid;
    border-radius: 8px;

    * {
        display: flex;
        margin: 0 5px;
    }

    &.DEAD {
        display: none;
    }

    &.IN_PROGRESS {
        color: #008090;
        border-color: #008090;
        background-color: #EEFFFF;
    }

    &.COMPLETE {
        color: #009000;
        border-color: #009000;
        background-color: #DDFFDD;
    }

    &.FAILURE {
        color: #900000;
        border-color: #900000;
        background-color: #FFDDDD;
    }

    .message {
        font-weight: bold;
        font-size: .75em;
    }
}
