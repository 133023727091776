@mixin contact-us-button {
    font-size: smaller;
    padding: 3px 5px;
    border: 1px solid;
    border-radius: 3px;
    font-weight: bolder;

    &:not([disabled]), &[disabled=false] {
        border-color: #767676;
        background-color: #FFFFFF;
        color: #555555;
        cursor: pointer;

        &:hover {
            color: #000000;
        }
    }
}

#contactUs {
    #contact-form {
        .form-line {
            margin: 5px 0;
            display: flex;

            .input-error {
                font-size: .75em;
                color: #D00000;
                display: inline;
                margin-left: 5px;
            }

            #name, #email-address, #subject, #message {
                &:focus-visible, &:focus, &:hover {
                    outline: none;
                }

                width: 200px;
                padding: 3px 5px;
                border: 1px solid #767676;
                border-radius: 3px;
                background-color: #ffffff;
                color: #333333;
            }

            #message {
                height: 100px;
            }

            #files-input {
                display: none;
            }

            #files-button {
                display: inline-block;
                @include contact-us-button();
            }

            #selected-files {
                font-size: .75em;

                ul {
                    padding-left: 25px;
                    margin: 0;
                    list-style-type: circle;

                    li {
                        margin: 3px 0;
                    }
                }
            }

            #submit {
                @include contact-us-button();
            }
        }
    }

    #email-form {
        .input-line {
            margin: 5px 0;
            display: flex;
        }

        #email-name, #email-address, #email-subject, #email-message {
            &:focus-visible, &:focus, &:hover {
                outline: none;
            }

            box-sizing: border-box;
            resize: none;
            padding: 3px 5px;
            border: 1px solid #767676;
            border-radius: 3px;
            background-color: #ffffff;
            color: #333333;
        }

        #email-message {
            width: 100%;
            height: 100px;
        }
    }
}
