#app {
    min-height: 100vh;
    margin: 0 auto;

    #nav-pane {
        display: inline-flex;
        box-sizing: border-box;
        background-image: url("../assets/nav-background.jpg");
    }

    #content-pane {
        display: inline-flex;
        box-sizing: border-box;
        padding: 10px;
        background-color: #363001A8;
        box-shadow: inset 1px 1px 25px #000000;

        #content {
            border-radius: 10px;
            margin: 0;
            padding: 10px;
            width: 100%;
            height: fit-content;
            background-color: #ffffffaa;
        }
    }
}

@media screen and (max-width: 850px) {
    #app {
        display: block;

        #nav-pane {
            width: 100%;
        }

        #content-pane {
            width: 100%;
        }
    }
}

@media not screen and (max-width: 850px) {
    #app {
        display: flex;
        max-width: 1111px;

        #nav-pane {
            float: left;
            width: 300px;
        }

        #content-pane {
            float: right;
            width: calc(100% - 300px);
        }
    }
}
