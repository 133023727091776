#forms {

    .file {
        margin: 5px 0;
    }

    .accordion__button {
        color: #444444C0;

        &:hover {
            color: #444444
        }

        cursor: pointer;
        margin-top: 10px;
        padding-left: 10px;
        text-align: left;
        border: none;
    }

    .accordion__button:before {
        display: inline-block;
        content: '';
        height: 10px;
        width: 10px;
        margin-right: 12px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg);
    }

    [hidden] {
        display: none;
    }

    .accordion__panel {
        padding: 5px 5px 5px 30px;
        animation: fadein 0.35s ease-in;
    }

    /* -------------------------------------------------- */
    /* ---------------- Animation part ------------------ */
    /* -------------------------------------------------- */

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
