#home {
    #upcoming-events {
        ul {
            margin: 5px 0;

            li {
                margin: 10px 0;
            }
        }
    }
}
