body {
    margin: 0;
    font-family: "Oxygen", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

@mixin link {
    text-decoration: none;
    color: #0088ffC0;
    font-weight: 200;
    cursor: pointer;
    &:hover {
        color: #0088ff;
    }
}

a, .clickable-text {
    @include link;
}
