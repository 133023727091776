
#nav {
    width: 100%;
    border: 2px solid #99660055;

    #name {
        padding: 15px 35px;
        text-align: center;
        color: #268d26b7;
        font-weight: bolder;
        font-size: 24px;
    }

    #nav-image {
        text-align: center;
        border-top: 2px solid #99660055;
        border-bottom: 2px solid #99660055;

        img {
            width: 100%;
            display: block;
        }
    }

    #smaller {
        //position: absolute;
        //top: 0;
        //left: 0;
        #nav-toggle {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #268d26b7;
        }
    }

    #nav-list {
        padding-inline-start: 0;
        margin: 0;

        li {
            display: block;
            list-style-type: none;
            padding: 15px 20px;

            a {
                box-sizing: content-box;
                font-weight: bold;
                color: #eecea880;
                transition-duration: 1.0s;

                &:link {
                    text-decoration: none;
                }

                &.active, &:hover {
                    color: #268d26b7;
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    #nav {
        #nav-image {
            display: none;
        }
        #larger {
            display: none;
        }
    }
}

@media not screen and (max-width: 850px) {
    #smaller {
        display: none;
    }
}

